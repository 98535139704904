import {Checkbox, FormControlLabel} from "@material-ui/core"
import MobileStepper from "@material-ui/core/MobileStepper"
import Lock from "../../../assets/icons/currentColor/icon-sicherheit_blau.svg"
import React from "react"
import {Trans, withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import styled from "styled-components"

import NotesBook from "../../../assets/icons/currentColor/notes-book-text_blue100.svg"
import BlueShield from "../../../assets/icons/currentColor/Schild_Blau100_2.svg"
import TaskMultiple from "../../../assets/icons/currentColor/task-list-multiple_blue100.svg"
import {IPAddressesBox} from "../../areas/Help/components/FirewallInformation"
import {HelpLine} from "../../areas/Help/components/HelpLine"
import {TasksTargetsCredentialsSchedules} from "../../areas/Help/HelpPage"
import {GlobalConstants, GREENBONE_EXTERNAL_IPS, GREENBONE_INTERNAL_IPS} from "../../constants"
import {Cookie} from "../../Helper/Cookie"
import {AbortButton, Button, FlexRow, Snackbar} from "@greenbone/cloud-component-library"
import {Bold, Headline, Paragraph} from "../../StyledComponents/Font/Font"
import {NotificationBox} from "../Box/NotificationBox"
import {openErrorSnackbar} from "../Snackbar/actions"
import {closeWelcomeDialog} from "./actions"
import {TaskContext} from "../../areas/Scanmanagement/TaskContext"

const Stepper = styled(MobileStepper)`
  background: transparent;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

const ButtonContainer = styled.div`
  width: 12.50rem;
  display: flex;
`;

const BackButtonContainer = styled(ButtonContainer)`
  justify-content: flex-start;
`;

const NextButtonContainer = styled(ButtonContainer)`
  justify-content: flex-end;
`;


const HeadlineContainer = styled(FlexRow)`
  margin-bottom: 1.25rem;
  align-items: baseline;
`;

type Props = {
    openErrorSnackbar: typeof openErrorSnackbar;
    callback: any;
    t: any;
}

class OnboardingStepper extends React.Component<Props, any> {
    state = {
        activeStep: 0,
        skipped: new Set(),
        accepted: false,
        gotoWizard: false
    };

    handleWizardChange = (event) => {
        this.setState(prevState => ({
            gotoWizard: !prevState.gotoWizard
        }));
    };

    handleChange = (event) => {
        this.setState({
            accepted: !this.state.accepted
        });
    };

    handleNext = () => {
        const {activeStep} = this.state;
        let {skipped} = this.state;
        if (this.isStepSkipped(activeStep)) {
            skipped = new Set(skipped.values());
            skipped.delete(activeStep);
        }
        this.setState({
            activeStep: activeStep + 1,
            skipped
        });
    };

    handleBack = () => {
        const {activeStep} = this.state;
        this.setState({
            activeStep: activeStep - 1
        });
    };

    isStepSkipped(step) {
        return this.state.skipped.has(step);
    }

    getStepContent = () => {
        const {t} = this.props;
        return [
            <React.Fragment>
                <HeadlineContainer>
                    <Headline>
                        <Bold>{t("components.welcome.header")}</Bold></Headline>
                </HeadlineContainer>
                <HelpLine icon={TaskMultiple}>
                    <Trans i18nKey={"components.welcome.content.part1"}>
                        Erstellen Sie eine <Bold>Aufgabe</Bold>, die aus einem <Bold>Ziel</Bold>,
                        <Bold> Anmeldedaten</Bold> (optional) und <Bold> einem Zeitplan</Bold> (optional) besteht
                    </Trans>
                </HelpLine>
                <HelpLine icon={BlueShield}>
                    <Trans i18nKey={"components.welcome.content.part2"}>
                        <Bold>Starten</Bold> Sie in der <Bold>Scanverwaltung die Scans Ihrer Aufgaben</Bold>.
                    </Trans>
                </HelpLine>
                <HelpLine icon={NotesBook}>
                    <Trans i18nKey={"components.welcome.content.part3"}>
                        <Bold>Sehen</Bold> Sie nach jedem Scan Ihre <Bold>Reports</Bold> ein.
                    </Trans>
                </HelpLine>
            </React.Fragment>,
            <React.Fragment>
                <HeadlineContainer>
                    <Headline>
                        <Trans i18nKey={"components.welcome.content.part4"}>
                            <Bold>Aufgaben</Bold>: Ziel,Anmeldedaten und Zeitplan
                        </Trans>
                    </Headline>
                </HeadlineContainer>
                <TasksTargetsCredentialsSchedules/>
            </React.Fragment>,
            <React.Fragment>
                <HeadlineContainer>
                    <Headline>
                        <Trans i18nKey={"components.welcome.content.scanAndReports.header"}>
                            <Bold>Scanverwaltung</Bold>: Scans und Reports
                        </Trans>
                    </Headline>
                </HeadlineContainer>

                <HelpLine icon={BlueShield} title={t("components.welcome.content.scanmanagement.header")}>
                    <Trans i18nKey={"components.welcome.content.scanmanagement.description"}>
                        Sobald Ihre zu <Bold>scannenden Hosts</Bold> (im <Bold>Ziel</Bold> hinterlegt) <Bold>validiert
                        worden
                        sind</Bold>, <Bold>starten</Bold> Sie in der <Bold>Scanverwaltung den Scan Ihrer Aufgabe</Bold>,
                        falls kein
                        Zeitplan hinterlegt wurde. Andernfalls greift der Zeitplan.
                    </Trans>
                </HelpLine>

                <HelpLine icon={NotesBook} title={t("components.welcome.content.report.header")}>
                    <Trans i18nKey={"components.welcome.content.report.details"}>
                        Ein <Bold>Report</Bold> ist das <Bold>Ergebnis eines Scans</Bold> und beinhaltet mögliche <Bold>gefundene
                        Schwachstellen</Bold>. Diese werden nach unterschiedlichen <Bold>Schweregraden sortiert</Bold>,
                        dargestellt.
                    </Trans>
                </HelpLine>
            </React.Fragment>
            ,
            <React.Fragment>
                <HeadlineContainer>
                    <Headline>
                        <Trans i18nKey={"components.welcome.content.two-2factor.header"}>
                            Zwei-Faktor-Authentifizierung
                        </Trans>
                    </Headline>
                </HeadlineContainer>

                <HelpLine icon={Lock} title={t("components.welcome.content.two-2factor.content.header")}>
                    <Trans i18nKey={"components.welcome.content.two-2factor.content.description"}>
                        <Paragraph>
                            In Ihrem GSP-Account werden sensible Daten gespeichert. Deshalb empfehlen wir Ihnen
                            die
                            Aktivierung der Zwei-Faktor-Authentifizierung.
                        </Paragraph>
                        <Paragraph>
                            Sollte Ihr Passwort irgendwie zugänglich werden, wird Ihr Account durch eine weitere
                            Barriere,
                            dem zweiten Faktor, vor fremden Zugriff geschützt.
                        </Paragraph>

                    </Trans>
                    <NotificationBox text={t("components.welcome.content.two-2factor.activate")}/>
                </HelpLine>

            </React.Fragment>
            ,
            <React.Fragment>
                <TaskContext.Consumer>
                    {({tasks, _loading}: any) => {
                        return <>
                            <HeadlineContainer>
                                <Headline>{t("components.welcome.content.settings.header")}</Headline>
                            </HeadlineContainer>
                            <Paragraph>
                                <Bold>
                                    {t("components.welcome.content.settings.description")}
                                </Bold>
                            </Paragraph>
                            <FlexRow fullWidth justifyContent="center">
                                <IPAddressesBox dark>
                                    <div>
                                        {t('components.welcome.content.settings.internalIps')} {GREENBONE_INTERNAL_IPS}
                                    </div>
                                    <div>
                                        {t('components.welcome.content.settings.externalIps')} {GREENBONE_EXTERNAL_IPS}
                                    </div>
                                </IPAddressesBox>
                            </FlexRow>
                            <Paragraph>
                                <Trans i18nKey={"components.welcome.content.firewall"}>
                                    In der <Bold>Firewall-Konfiguration</Bold> nennt sich dieser Punkt häufig <Bold>"Whitelist/Exception
                                    for IPS"</Bold> (Intrusion Prevention System).
                                </Trans>
                            </Paragraph>
                            <Paragraph>
                                <NotificationBox text={
                                    <>
                                        {t("help.components.firewallInformation.importantInformation")}
                                        <br/>
                                        <br/>
                                        {t("scanConfigurations.always")}
                                    </>
                                }/>
                            </Paragraph>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={this.state.accepted}
                                        onChange={this.handleChange}
                                        value="checked"
                                    />
                                }
                                label={t("welcome.screenContent.acceptTerms")}
                            />
                        </>;
                    }}
                </TaskContext.Consumer>

            </React.Fragment>
        ];
    };

    BackButton = () => {
        const {t} = this.props;
        if (this.state.activeStep > 0) {
            return <BackButtonContainer>
                <AbortButton onClick={this.handleBack}>{t("common.action.back")}</AbortButton>
            </BackButtonContainer>;
        } else {
            return <BackButtonContainer/>;
        }
    };

    NextButton = () => {
        const {t} = this.props;
        if (this.state.activeStep === this.getStepContent().length - 1) {
            return <NextButtonContainer><Button
                variant="contained"
                color="primary"
                onClick={() => {
                    if (this.state.accepted === false) {
                        Snackbar.Error(t("welcome.screenContent.youHaveToAcceptTerms"));
                        return;
                    }

                    Cookie.set(GlobalConstants.WELCOME_WIZARD_ACCEPTED, true.toString(), 30);
                    this.props.callback(this.state.gotoWizard);
                }}>
                {t("welcome.screenContent.letsGO")}
            </Button></NextButtonContainer>;
        } else {
            return <NextButtonContainer><Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}>
                {t("common.action.continue")}
            </Button></NextButtonContainer>;
        }
    };

    render() {
        const {activeStep} = this.state;

        return (
            <Container>
                <div>
                    {this.getStepContent()[activeStep]}
                </div>

                <Stepper
                    variant={"dots"}
                    steps={this.getStepContent().length}
                    position={"static"}
                    activeStep={activeStep}
                    style={{background: "transparent"}}
                    nextButton={
                        this.NextButton()
                    }
                    backButton={
                        this.BackButton()
                    }
                />
            </Container>
        );
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openErrorSnackbar: openErrorSnackbar,
        closeWelcomeDialog: closeWelcomeDialog
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const WelcomeScreenContent = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(OnboardingStepper);
