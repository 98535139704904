//@flow

import React from "react"
import {Trans} from "react-i18next"
import styled, {css} from "styled-components"
import {GREENBONE_EXTERNAL_IPS, GREENBONE_INTERNAL_IPS} from "../../../constants"
import {Bold, Paragraph} from "../../../StyledComponents/Font/Font"
import {Colors} from "../../../Theme"


const Box = styled.div`
  border-top: 0.31rem solid ${Colors.yellow.normal};
  background: ${Colors.yellow.light};
  margin-bottom: 1.25rem;
`

const TextBox = styled.div`
  padding: 0 0.94rem 0.94rem 0.94rem;
  color: ${Colors.grey.dark};
`;

export const IPAddressesBox = styled.div`
  border: 1px solid ${Colors.grey.dark};
  border-radius: 2px;
  padding: 0.31rem;
  padding-left: 0.63rem;
  padding-right: 0.63rem;
  display: inline-block;
  font-weight: bold;

  ${props => props.dark && css`
    border: 1px solid ${Colors.grey.normal};
    color: ${Colors.grey.darker};
  `}
`;

export const FirewallInformation = () => {
    return (<Box>
        <TextBox>
            <Paragraph>
                <Trans i18nKey={"firewallInformation.1"}>
                    <Bold>
                        Für ein optimales Ergebnis müssen folgende IP-Adressen auf Ihrer Firewall freigeschaltet werden:
                    </Bold>
                </Trans>
            </Paragraph>

            <IPAddressesBox dark>
                {t('components.welcome.content.settings.externalIps')} {GREENBONE_EXTERNAL_IPS}
                {t('components.welcome.content.settings.internalIps')} {GREENBONE_INTERNAL_IPS}
            </IPAddressesBox>

            <Paragraph>
                <Trans i18nKey={"firewallInformation.2"}>
                    In der <Bold>Firewall-Konfiguration</Bold> nennt sich dieser Punkt häufig <Bold>"Whitelist/Exception
                    for IPS"</Bold> (Intrusion Prevention System).
                </Trans>
            </Paragraph>
        </TextBox>
    </Box>);
};
