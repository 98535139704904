import React from "react";
import { Container } from "reactstrap";
import { i18n } from "../../../i18n";
import ErrorBoundary from "../../Components/ErrorBoundary";
import { globalInstanceStorage } from "../../../GlobalSingle";
import { HasUserRole } from "../../Components/HasUserRole";

const Security = ({ accountUrl, language }) => {
    const iframeUrl = accountUrl.replace(/\/account(\?.*)?$/, "/account/account-security/signing-in$1")
        + `&kc_locale=${language}`;

    return (
        <Container fluid={true}>
            <iframe
                title="Security Settings"
                src={iframeUrl}
                style={{ width: "100%", height: "880px", borderStyle: "none" }}
            />
        </Container>
    );
};

export function SecurityPage() {
    const language = i18n.language;
    const accountUrl = globalInstanceStorage.getKeycloak().createAccountUrl();

    return (
        <ErrorBoundary>
            <HasUserRole>
                <Security accountUrl={accountUrl} language={language} />
            </HasUserRole>
        </ErrorBoundary>
    );
}
